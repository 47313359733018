/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from 'react';
import { uuid } from '@/utils/tools';
import footerLogoImg from '../../static/imgs/footer-logo.png';
import './index.scss';

const navList0 = [
  {
    name: '地址：上海市杨浦区淞沪路433号创智天地6号楼8楼',
  },
  {
    name: '客服电话：400-0166-088',
  },
];

const navList1 = [
  // {
  //   link: 'https://join.paipai.com/',
  //   name: '入驻拍拍',
  // },
  // {
  //   link: 'https://join.paipai.com/#contact',
  //   name: '联系我们',
  // },
  // {
  //   name: '地址：广东省深圳市南山区留仙大道4168号众冠时代广场A座1911',
  // },
  {
    name: '违法不良信息举报电话：0519-88236605',
  },
  {
    name: '上海市互联网举报中心',
    link: 'http://www.shjbzx.cn',
  },
  {
    name: '网上有害信息举报专区',
    link: 'https://www.12377.cn',
  },
].map((item) => ({
  ...item,
  uuid: uuid(),
}));

const navList2 = [
  {
    link: 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011002006250',
    name: (
      <>
        <img
          src="https://img12.360buyimg.com/img/s20x20_jfs/t1/134161/16/7891/19257/5f435c7eE41e35bd8/5e71aafa7ce21e66.png"
          alt=""
          style={{ marginRight: 5 }}
        />{' '}
        沪公网安备 31011002006250号
      </>
    ),
  },
  {
    link: 'https://beian.miit.gov.cn/#/Integrated/index',
    name: '沪ICP备17018163号',
  },
  {
    // link: 'https://img20.360buyimg.com/paipai/jfs/t1/82588/9/4907/393686/5d318023E676bdb75/9fc81f94073da0da.jpg', // 旧版
    // link: 'https://img10.360buyimg.com/paipai/jfs/t1/130739/13/21679/359939/6201f379Eb1c612d6/ca3d6fb219cd7759.jpg',
    link: 'https://img10.360buyimg.com/paipai/jfs/t1/237095/9/18404/3567479/66739c7eF91a8bc75/7e459e10c8dde31c.png', // 上海悦亿
    name: '营业执照',
  },
  {
    link: 'https://paipai-fe-cdn.s3.cn-north-1.jdcloud-oss.com/电信业务许可证/宝山悦亿EDI.pdf',
    name: '增值电信业务经营许可证',
  },
  // {
  //   // link: 'https://img13.360buyimg.com/devfe/jfs/t1/40075/15/12169/1008208/5d35c914E579d71a3/e3c6d45c7f531416.jpg', // 旧版
  //   link: 'https://img10.360buyimg.com/paipai/jfs/t1/103222/12/22780/465433/6201ef50E3af455d5/e8b4e1c5da3b0017.jpg',
  //   name: '增值电信业务经营许可证：粤B2-20190359',
  // },
  {
    name: 'Copyright©2017-2025 拍拍paipai.com版权所有',
  },
].map((item) => ({
  ...item,
  uuid: uuid(),
}));

// eslint-disable-next-line react/prop-types
const FooterLine = memo(({ list = [], className = '' }) => (
  <div className={`footer-line ${className}`}>
    {list.map((item, index) =>
      item.link ? (
        <a
          className={`link ${index === list.length - 1 ? 'link_last' : ''}`}
          href={item.link}
          target="_blank"
          rel="nofollow noopener noreferrer"
          key={item.uuid}
        >
          {item.name}
        </a>
      ) : (
        <span className={`link ${index === list.length - 1 ? 'link_last' : ''}`} key={item.uuid}>
          {item.name}
        </span>
      ),
    )}
  </div>
));

/**
 * footer
 * @returns
 */
const Footer = () => (
  <div className="wrap cp-footer">
    <div className="content">
      <div className="footer-logo">
        <a href="/">
          <img src={footerLogoImg} alt="拍拍" />
        </a>
      </div>
      <div className="footer-content">
        <FooterLine className="footer-line_1" list={navList0} />
        <FooterLine className="footer-line_1" list={navList1} />
        <FooterLine className="footer-line_2" list={navList2} />
      </div>
    </div>
  </div>
);

export default memo(Footer);
